$dark_primary: #1B2735;
$dark_secondary: #202124;
$dark_accent: #fdcb6e;

$light_primary: #F4F4F4;
$light_secondary: #FFFFFF;
$light_accent: rgb(0, 119, 255);
$light_accent2: rgb(132, 189, 255);


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
  background-color: #1B2735;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.title {
  font-size: 50px;
  letter-spacing: 3px;
  text-align: right;
  padding-right: 50px;
  padding-top: 20px;
}

/* Home page CSS */
.main {
  min-height: calc(100vh - 20px);
}


.home-titles {  
  width: 100vw;
  text-align: center;
}

.home-subheading{
  font-size: 20px;
  letter-spacing: 2px;
}


.hero {
  letter-spacing: 10px;
  font-size: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 50px);
}

.icons svg{
  padding: 20px;
  padding-top: 50px;
}

.icon {
  border: none;
}

.loop {
  padding-top: 35px;
}

/* Box CSS */

.box {
  padding: 30px;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  
}

.box-left {
  flex: 35%;
  padding: 20px;
  text-align: right;
}

.box-right {
  flex: 65%;
  padding: 20px;
}

.box-line {
  border-bottom: 1px solid white;
  margin-left:50px;
  margin-right:50px;
}

.box-badge-matrix {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-top: 10px;
}

.box-badge-wrapper {
  padding: 5px;
}


.box-badge{
  border-radius: 25px;
  border: 1px solid white;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  font-size: 12px;
}

.box-heading {
  font-size: 30px;
  padding-bottom: 5px;
  text-decoration: none;
}

.box-subheading {
  font-size: 20px;
}

.box-date {
  font-size: 16px;
}

.box-description {
  padding-top: 5px;
  font-size: 16px;
}

.box-awards{
  font-size: 14px;
  padding: 2px;
}

/* Me Page CSS */ 

.me-outer {
  padding: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
}

.me-left {
  flex: 35%;
  padding: 20px;
  // text-align: center;
}

.me-right {
  flex: 65%;
  padding: 20px;
}

.me-avatar {
  border-radius: 50%;
  width: 30%;
}

.me-titles {
  padding-top:10px;
  font-size: 20px;
}

.me-heading{
  padding-top:10px;
  font-size: 24px;
  letter-spacing: 3px;
}

.me-subheading{
  padding-top:60px;
  font-size: 16px;
  letter-spacing: 3px;
}

.me-about{
  padding-top:10px;
  font-size: 16px;
}

.me-footer {
  padding-top: 60px;
}

.me-table-header{
  font-size: 24px;
  letter-spacing: 3px;
  padding-bottom: 30px;
}

.me-table{
  font-size: 16px;
  width: 100%;
  margin-bottom: 50px;
  border-collapse: collapse;
}

.me-table td{
  padding: 10px;
}

.me-table-title {
  width: 65%;
}

.me-table-data {
  width: 35%;
  text-align: right;
}

.me-table tr:nth-child(even) {background-color: #090A0F59;}

.show-mobile-only {
  display:none;
}

.show-desktop-only {
  display:block;
}

/* Map CSS */ 

#chartdiv {
  width: 100%;
  height: calc(100vh - 101px);
  overflow: hidden;
}

/* Project Page CSS */

.project {
  padding-left: 30px;
  padding-right: 30px;
  margin: 20px;
  margin-left: 10px;  
  margin-right: 10px;
  text-align: left;
  width: 100%;
  cursor:pointer;
  color:white;
}



.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-left:10px;
  padding-right: 10px;
}

.project-collapsed {
  display:none;
}

.project-expanded {
  display: block;
}

.project-badge-matrix {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  float:right;
  width: 50%;
}

.project-header {
  font-size: 30px;
}
.project-subheader {
  font-size: 24px;
  font-weight: normal;
  color:white;
}

.project-short-desc {
  font-size: 18px;
  font-style: italic;
  color: white;
  // position: absolute;
  // bottom: 0px;
  // background-color: rgba(0,0,0, 0.6);
  // padding: 10px;
  // width: 100%;
}

.project-long-desc {
  font-size: 14px;
  padding-top: 20px;
}

.hidden {
  display: none;
}

.projects-button{
  margin: 0 auto;
  display: block;
  padding: 10px;
  margin-bottom: 40px;
  margin-top: 20px;
  font-size: 20px;
  border:none;
  border-radius: 25px;
  cursor: pointer;
  transition-duration: 0.4s;
}

.projects-button:hover {
  text-decoration: underline;
}

.project-image{
  width:100%;
  height: 40vh;
  object-fit: cover;
  display: block;
}

.project-image-container {
  position:relative;
}

.project-header-container {
  position: absolute;
  bottom: 0px;
  background-color: rgba(0,0,0, 0.6);
  padding: 10px;
  width: 100%;
}

.project-img-zoom {
  overflow: hidden;
}

.project-img-zoom img {
  transition: transform .5s ease;
}

.project-img-zoom:hover img {
  transform: scale(1.1);
}

.project-badge{
  border-radius: 25px;
  border: 1px solid white;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  font-size: 12px;
}

/* Individual project pages CSS */ 

.project-page-content {
  padding:30px;
}

.project-page-long-desc {
  font-size: 14px;
  padding-top: 20px;
}

.project-page-layout {
  display: flex;
  flex-wrap: wrap;
}

.project-page-media-container {
  flex: 60%;
  padding-top: 70px;
  text-align: center;
}

.project-page-text-container {
  flex: 40%;
}

.project-page-image {
  max-height: 400px;
  padding: 10px;
  max-width: 100%;
}

.project-page-video {
  width: 100%;
  padding: 10px;
}

.project-page-audio-player {
  width: 100%;
  padding: 10px;
}

.project-page-song h2{
  text-align: right;
  padding-right: 15px;
}

.project-page-links {
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.project-page-icon {
  border: none;
  padding: 5px;
}

.project-page-icon img{
  height: 3em;
}

.project-page-icon:hover{
	transform: scale(1.2); 
}

.project-page-header {
  font-size: 50px;
}

.project-page-subheader {
  font-size: 24px;
  font-weight: normal;
}

.project-page-badge{
  border-radius: 25px;
  border: 1px solid white;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  font-size: 12px;
}

.project-page-short-desc {
  font-size: 18px;
  font-style: italic;
  // position: absolute;
  // bottom: 0px;
  // background-color: rgba(0,0,0, 0.6);
  // padding: 10px;
  // width: 100%;
}

.project-page-paragraph {
  padding-bottom: 14px;
}

.project-page-table {
  flex: 100%;
  padding-top: 10px;
}

.project-page-table-desktop {
  width: 100%;
  display: table;
  text-align: center;
}

.project-page-table-header {
  font-size: 14px;
}

.project-page-table-desktop tr:nth-child(even) {background-color: #090A0F59;}

.project-page-table-empty {
  background: repeating-linear-gradient(
      45deg,
      #090A0F59,
      #090A0F59 10px,
      transparent 10px,
      transparent 20px
    );
}

.project-page-table-desktop th {
  background-color: black;
  color: white;
}


.project-page-table-desktop th, td {
  padding: 5px;
}

.project-page-table-mobile {display: none;}


/* 404 Page CSS */

.error-image {
  width: 100%;
  padding: 15vh;
}

/* Nav Bar CSS */ 

.nav {
  background-color: #202124;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 250px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  justify-content: space-between;
  text-align: center;

  border-right: solid;
  border-right-width: thin;

  .nav-brand {
    text-align: center;

    img {
      height: 120px;
      width: 120px;
      display: block;
      margin: 0 auto 2rem auto;
      border-radius: 100%;
    }
    a {
      font-size: 30px;
      font-weight: 500;
      text-decoration: none;
      border: none;
    }
  }

  .nav-items {
    display: flex;
    flex-direction: column;
    padding-inline-start: 0;
    justify-content: center;
    font-size: 20px;

    a {
      margin-bottom: 2rem;
      text-decoration: none;
      border: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  hr {
    border-color: white;
    margin: 20px 0 5px 0;
    width: 100%;
  }

  .footer .btn {
    width: 100%;
    
  }
}

.nav-socials {
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.nav-icon {
  border: none;
  padding: 5px;
}

.nav-titles {
  text-align: center;
  width: 200px;
  padding-top:10px;
  font-size: 20px;
}

.hamburgerBtn,
.closeBtn{
  display: none;
  background-color: transparent;
  border: none;
}

.hamburgerBtn:hover {
  cursor: pointer;
}


.layout {
  margin-left: 250px;
  min-height: 100vh;
  padding-top: 2rem;
}

/* Nav Bar Mobile Specific CSS */ 

@media only screen and (max-width: 896px) {
  .nav {
    transform: translateX(-100vw);
    transition: transform 500ms;
    border: none;

    .nav-brand {
      .nav-brand-img {
        display: none !important;
      }
      
      .nav-brand-link {
        font-size: 2.4rem;
      }
    }

    .nav-items {
      align-items: center;

      .nav-item {
        display: block;
        margin-bottom: 0.5rem;

        & > a {
          font-size: 1.8rem;
        }
      }
    }
  }

  .hamburgerBtn,
  .closeBtn {
    position: fixed;
    top: 2rem;
    left: 2rem;
    display: inline-block;
    z-index: 100;
    color: white;
  }



  .nav.open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.98;
    width: 100%;
    z-index: 10;
    transform: translateX(0);
    padding-top: 10rem;
    padding-bottom: 20rem;
  }

  .layout {
    margin-left: 0;
  }
}

/* Landscape Mobile CSS for Nav */ 
@media only screen and (max-width: 896px) and (min-width: 481px) {

  .nav-brand {
    display: none;
  }

  .footer {
    display:  none;
  }

  .nav.open {
    padding-top: 5rem; 
  }
}


/* Mobile-specific classes for iPhone 5 and below */
@media only screen 
and (max-width : 325px) {

  .hero{
    font-size: 20px;
  }

  .icons {
    font-size: 30px;
  }

  .icons svg {
    padding: 0px;
    padding-top: 30px;
  }

} 

/* Mobile-specific classes for iPhone 6 and above */
@media only screen 
and (min-width : 326px) and (max-width: 481px) {

  .hero{
    font-size: 40px;
  }

  .icons {
    font-size: 50px;
  }

} 

/* Mobile-specific classes */
@media screen and (max-width: 481px){

  .title {
    font-size: 50px;
    letter-spacing: 3px;
    text-align: left;
    padding-right: 0px;
    padding-left: 20px;
    padding-top: 20px;
  }

  .main {
    padding-top: 25px;
    padding-left: 0px;
  }

  /* Home page CSS */
  .hero {
    width: 100%;
    letter-spacing: 0px;
    justify-content: start;
  }

  /* Box CSS */
  .box {   
    flex-direction: column;
    padding: 5px;
  }

  /* Me CSS */ 

  .me-outer {
    flex-direction: column;
    padding: 5px;
  }

  .me-subheading {
    padding-top: 30px;
  }

  .me-footer{
    padding-top: 30px;
  }

  .show-mobile-only {
    display:block;
  }

  .show-desktop-only {
    display:none;
  }

  /* Map CSS */ 

  #chartdiv {
    height: calc(50vh);
  }

  /* Project Page CSS */

  .project{
    margin-left: 10px;  
    margin-right: 10px;
    padding: 0px;
  }

  .projects {
    padding-top: 20px;
  }

  .project-small{
    width: 100%;
  }

  /* Individual project page CSS */
  .project-page-layout{
    flex-direction: column;
  }

  .project-page-media-container {
    padding-top: 10px;
  }

  .project-page-table-desktop {display: none;}
  .project-page-table-mobile {
    display: inherit;
    text-align: center;
    font-size: 14px;
  }

  .project-page-table-mobile h4{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .project-page-table-mobile-icon {
    margin-top: 10px
  }

  .project-page-song h2{
    padding-top: 15px;
  }
  

  /* 404 Page CSS */
  
  .error-image {
    padding: 2vh;
    padding-top: 10vh;
  }
}




/* Light Mode CSS */

@media (prefers-color-scheme: light) {
  .nav {
    background-color: $light_secondary;
    border-right-color: rgba(160,160,160,0.3);
  }

  body {
    color:black;
    background-color: $light_primary;
  }

  a {
    color:black;
    border-bottom: dotted 1px black;
  }
  .icons svg {
    color: black;
  }

  .active_nav {
    color: $light_accent !important;
  }

  .box-badge {
    color: $light_accent;
    border-color: $light_accent;
  }

  .box-heading {
    color: $light_accent;
  }

  .hamburgerBtn{
    color: $light_accent;
  }

  .closeBtn > svg {
    color: $light_accent;
  }

  .project {
    // background-color: $light_secondary;
  }

  .project-header {
    color: $light_accent2;
  }

  .project-badge {
    color: $light_accent2;
    border-color: $light_accent2;
  }

  .projects-button{
    background-color: $light_accent;
    color:$light_primary;
  }

  .project-page-header {
    color: $light_accent;
  }

  .project-page-badge {
    color: $light_accent;
    border-color: $light_accent;
  }

  .title {
    color: $light_accent;
  }

  .accent-colour {
    color: $light_accent;
  }
  
} 

/* Dark Mode CSS */

@media (prefers-color-scheme: dark) {
  
  .nav {
    background-color: $dark_secondary;
    border-right-color: rgba(67,67,67,0.3);
  }

  body {
    color: white;
    background-color: $dark_primary;
  }

  a {
    color:white;
    border-bottom: dotted 1px white;
  }
  
  .icons svg {
    color: white;
  }

  .active_nav {
    color: $dark_accent !important;
  }

  .box-badge {
    color: $dark_accent;
    border-color: $dark_accent;
  }

  .box-heading {
    color: $dark_accent;
  }

  .closeBtn > svg {
    color: white;
  }

  .project {
    // background-color: $dark_secondary;
  }

  .project-badge {
    color: $dark_accent;
    border-color: $dark_accent;
  }

  .project-header {
    color: $dark_accent;
  }

  .projects-button{
    background-color: $dark_accent;
    color:$dark_primary;
  }

  .project-page-header {
    color: $dark_accent;
  }

  .project-page-badge {
    color: $dark_accent;
    border-color: $dark_accent;
  }

  .title {
    color: $dark_accent;
  }

  .accent-colour {
    color: $dark_accent;
  }

}

